body{
    background-color: rgb(1, 1, 1);
    font-style: inherit;
    font-family: Inter,Helvetica,Apple Color Emoji,Segoe UI Emoji,NotoColorEmoji,Noto Color Emoji,Segoe UI Symbol,Android Emoji,EmojiSymbols,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Noto Sans,sans-serif;
 }
.image1{
    height: 75px;
    width:175px;
    border: 2px solid green;
}

.arrow{
    margin-top: -30px;
    height:20px;
    width:20px;
    margin-left: 264px;
}
.arrow2{
    margin-top: -33px;
    margin-left: 330px;
    height:20px;
    width:20px;
}
@media screen and (max-width: 500px){
    .arrow{
    margin-left: 265px;
    margin-top: -40px;
    height:30px;
    width:30px;
    }
    .arrow2{
        margin-top: -48px;
        margin-left: 280px;
        height:20px;
        width:20px;
    }
 };
